import { Flex, HStack, Box } from "@chakra-ui/react";
import ImageWithFallback from "@components/ImageWithFallback";
import Image from "next/image";
import fallbackImg from "../../../public/login-assets/hermesloginimage.png";
import useTenant from "@hooks/useTenant";

const AuthLayout = ({ children }) => {
    const { authImage } = useTenant();
    return (
        <Flex minH="100vh" bg="white" w="full">
            <HStack
                overflow="hidden"
                justify="start"
                w="50%"
                display={{ base: "none", lg: "flex" }}
            >
                <Box
                    position="relative"
                    w="full"
                    h="full"
                    sx={{ img: { objectFit: "cover" } }}
                >
                    <ImageWithFallback
                        alt="Login Image"
                        src={authImage}
                        fallback={fallbackImg}
                        fill
                        quality={100}
                        priority
                    />
                </Box>
            </HStack>
            <Flex
                px={{ base: "8", lg: "12" }}
                py="16"
                bg="white"
                w={{ base: "full", lg: "50%" }}
                direction="column"
                align="center"
            >
                <Flex
                    h="full"
                    maxW="md"
                    w="full"
                    direction="column"
                    align="start"
                    gap="3"
                >
                    <Flex
                        direction="column"
                        grow={1}
                        w="full"
                        justify="start"
                        align="center"
                        pt={{ base: "7", xl: "51" }}
                    >
                        {children}
                    </Flex>
                    <HStack w="full" justify="center">
                        <Image
                            alt="Hermes Logo"
                            src="/login-assets/hermes.svg"
                            width="140"
                            height="28"
                        />
                    </HStack>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default AuthLayout;
