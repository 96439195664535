import { msg } from "@lingui/core/macro";
import * as yup from "yup";

yup.setLocale({
    mixed: {
        default: msg`This field is required`,
        required: msg`This field is required`,
    },
    string: {
        matches: () => msg`Only alphabets are allowed for this field`,
        max: () => msg`Must be a maximum of 255 characters`,
        email: () => msg`Invalid email format`,
    },
});

export default yup;
