import { useMemo } from "react";
import { DEFAULT_LOCALE } from "@constants/common";
import { useLingui } from "@lingui/react";
import useTenant from "./useTenant";

export default function useTranslation() {
    const tenant = useTenant();

    const { i18n } = useLingui();

    /* get locale from tenant context */
    const locale = useMemo(
        () => tenant?.user?.languageCode || tenant?.locale || DEFAULT_LOCALE,
        [tenant]
    );

    return { i18n, locale };
}
