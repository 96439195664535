import { Button, Stack, Text, Switch, Flex, Box } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import schema from "@schemas/login";
import Link from "next/link";
import FormInput from "./FormInput";
import AlertValidationErrors from "@components/AlertValidationErrors";
import useTranslation from "@hooks/useTranslation";

interface LoginFormProps {
    onSubmit: (values: FieldValues) => void;
    isLoading: boolean;
    errors: string[];
}

export const LoginForm = ({ onSubmit, isLoading, errors }: LoginFormProps) => {
    const { i18n } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    return (
        <>
            <Stack
                w="full"
                mt={5}
                as="form"
                spacing="4"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormInput
                    size="md"
                    register={register}
                    label={i18n.t("Username")}
                    error={formErrors?.username}
                    type="text"
                    name="username"
                    placeholder={i18n.t("Username")}
                />
                <Box>
                    <FormInput
                        size="md"
                        register={register}
                        label={i18n.t("Password")}
                        error={formErrors?.password}
                        name="password"
                        placeholder={i18n.t("Password")}
                        type="password"
                    />
                    <Text
                        pos="relative"
                        top={0}
                        mt={
                            Object.keys(formErrors).length ? "-67px" : "-50.5px"
                        }
                        fontSize={"xs"}
                        color="blue.500"
                        letterSpacing={0.5}
                        fontWeight="semibold"
                        textAlign="end"
                        _hover={{ textDecoration: "underline" }}
                    >
                        <Link href="/auth/forgot-password">
                            {i18n.t("Forgot password?")}
                        </Link>
                    </Text>
                </Box>

                <Flex flexDir={"column"} mt="10">
                    {/* Validation Errors */}
                    <AlertValidationErrors
                        width="full"
                        backgroundColor={"alert.50"}
                        color={"alert.100"}
                        borderColor={"alert.100"}
                        alertMessage="Error: These credentials do not match our records."
                        errors={errors}
                    />
                    <Stack
                        mt={"4"}
                        direction="row"
                        align="center"
                        color="text.1200"
                        w={"full"}
                    >
                        <Switch mt={"1"} size="md" id="rememberMe"></Switch>
                        <Text
                            fontSize={"xs"}
                            pt={"1"}
                            color="blackAlpha.600"
                            letterSpacing={0.7}
                            fontWeight="medium"
                        >
                            {i18n.t("Remember me")}
                        </Text>
                    </Stack>

                    <Button
                        mt={"4"}
                        letterSpacing={0.7}
                        fontWeight="semibold"
                        type="submit"
                        variant={"solid"}
                        isLoading={isLoading}
                    >
                        {i18n.t("Log In")}
                    </Button>
                </Flex>
            </Stack>
        </>
    );
};
